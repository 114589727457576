<template>
  <div class="CmsContentsFiledsList">
    <div class="contentsWidth mx-auto mt-4">

      <div class="">
        <p class="title mb-0">マイページ作成者 - 一覧</p>
        <hr class="title">
      </div>

      <div id="serchWrap" class="mt-4">
        <div class="bold">絞り込み条件</div>
        <div class="search_area flex flexCenter">
          <div class="inlineBlock searchSpace">
            <div>氏名</div>
            <div>
              <b-form-input
                v-model="filter.name"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="inlineBlock searchSpace searchMiddleLong">
            <div>メールアドレス</div>
            <div>
              <b-form-input
                v-model="filter.mail_address"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="inlineBlock searchSpace searchMiddle">
            <div>所属機関</div>
            <div>
              <b-form-input
                v-model="filter.institution"
                placeholder="入力してください。"/>
            </div>
          </div>
          <div class="inlineBlock searchSpace">
            <div>生年月日</div>
            <div>
              <b-form-input
                v-model="filter.birth"
                placeholder="入力してください。"/>
            </div>
          </div>
        </div>
      </div>

      <div class="contentsWidth mt-2">
        <div class="flex flex-between my-2">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>

        <b-table striped hover
          id='fieldTable'
          table-class="cmsAppTable"
          thead-class="tableHead"
          tbody-tr-class="dataWrap"
          :items="userList"
          :fields="header"
          :filter="filter"
          :filter-function="filtering"
          @filtered="onFiltered"
          show-empty
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:emptyfiltered="scope">
            <div class="flex flexCenter">条件に一致するデータがありません。</div>
          </template>
          <template
            v-slot:head(checkbox)>
            <b-link
              id="popover"
              class="ml-1">選択</b-link>
            <b-popover
              target="popover"
              title=""
              triggers="hover"
              placement="right"
            >
              <template v-slot:default>
                <p class="mb-0 link" @click="pageSelect">ページ内全選択</p>
                <p class="mb-0 link" @click="pageRemove">ページ内全解除</p>
                <p class="mb-0 link" @click="allSelect">全選択</p>
                <p class="mb-0 link" @click="allRemove">全解除</p>
              </template>
            </b-popover>
          </template>
          <template v-slot:cell(checkbox)="row">
            <div class="flex flexCenter">
              <b-form-checkbox
                @change="setSelectedUserIdList($event)"
                :checked="selectedUserIdList"
                :value="row.item.id"
                />
            </div>
          </template>
          <!-- kanaでソートをするが、表示を漢字名 -->
          <template v-slot:cell(kana)="row">
            {{row.item.name}}
          </template>
        </b-table>

        <div class="flex flex-between my-2" v-if="totalRows > 0">
          <b-pagination
            class="mb-1"
            aria-controls="fieldTable"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          />
          <div>
            <div class="inlineBlock mr-2">
              {{nowPageCount}}件 / 全{{totalRows}}件
            </div>
            <div class="inlineBlock">
              <span class="mr-2">表示件数</span>
              <b-form-select
                class="per-page-select"
                v-model="perPage"
                :options="pageOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 mb-3 flex flexCenter contentsWidth mx-auto">
      <b-button
        class="btn btn-lg bold"
        to="/cms/top">トップに戻る</b-button>
      <b-button
        @click="destroy"
        variant="primary"
        class="btn btn-primary btn-lg bold ml-2"
        >削除</b-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';

export default {
  name: 'CmsUsersList',
  data() {
    return {
      dbUserList: [],
      filterdIdList: [],
      selectedUserIdList: [],
      filter: {
        name: '',
        mail_address: '',
        institution: '',
        birth: '',
      },
      header: [
        { key: 'checkbox', label: '', sortable: false },
        { key: 'kana', label: '申請者名', sortable: true },
        { key: 'mail_address', label: 'メールアドレス', sortable: true },
        { key: 'institution', label: '所属機関', sortable: true },
        { key: 'birth', label: '生年月日', sortable: true },
        { key: 'created_at', label: '登録日時', sortable: true },
      ],
      pageOptions: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        // { value: 0, text: 'すべて' },
      ],
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
    };
  },
  methods: {
    // フィルター用 search変更時に各行に対して走る
    filtering(lineData, search) {
      const nameFilter = search.name === '' || lineData.name.includes(search.name);
      const mailFilter = search.mail_address === '' || lineData.mail_address.includes(search.mail_address);
      const institutionFilter = search.institution === '' || lineData.institution.includes(search.institution);
      const birthFilter = search.birth === '' || String(lineData.birth).includes(search.birth);
      return nameFilter && mailFilter && institutionFilter && birthFilter;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.filterdIdList = filteredItems.map((data) => {
        return data.id;
      });
      this.currentPage = 1;
    },
    async initFetch() {
      const response = await api.send('/api/user/gas/list')
        .catch((err) => {
          console.log(err);
        });
      this.dbUserList = response.data.userList;
      this.filterdIdList = this.dbUserList.map((data) => {
        return data.id;
      });
    },
    async destroy() {
      const number = this.selectedUserIdList.length;
      const msg = `${number}件のユーザーが選択されています。\n削除してもよろしいですか？`;
      if (!await this.confirm(msg)) {
        return;
      }
      this.$store.dispatch('page/onWaiting');
      const param = {
        userList: this.selectedUserIdList,
      };
      const response = await api.send('/api/user/gas/destroy', param)
        .catch((err) => {
          console.log(err);
        });
      if (!response) {
        this.$store.dispatch('page/offWaiting');
        this.alert('ユーザーの削除に失敗しました。');
        return;
      }
      this.alert('ユーザーを削除しました。');
      this.initFetch();
      this.$store.dispatch('page/offWaiting');
    },
    allSelect() {
      this.selectedUserIdList = this.filterdIdList.slice();
    },
    allRemove() {
      this.selectedUserIdList = [];
    },
    pageSelect() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((userId) => {
        this.updateSelectedUserIdList(true, userId);
      });
    },
    pageRemove() {
      const start = (this.currentPage - 1) * (this.perPage);
      const end = this.currentPage * this.perPage;
      const idListOnPage = this.filterdIdList.slice(start, end);
      idListOnPage.forEach((userId) => {
        this.updateSelectedUserIdList(false, userId);
      });
    },
    setSelectedUserIdList(list) {
      this.selectedUserIdList = list;
    },
    updateSelectedUserIdList(event, userId) {
      if (event) {
        // つける
        if (this.selectedUserIdList.indexOf(userId) === -1) {
          this.selectedUserIdList.push(userId);
        }
      } else {
        // 外す
        const index = this.selectedUserIdList.indexOf(userId);
        if (index !== -1) {
          this.selectedUserIdList.splice(index, 1);
        }
      }
    },
  },
  computed: {
    userList() {
      return this.dbUserList.map((data) => {
        return {
          id: data.id,
          name: `${data.mst_user_info.sei} ${data.mst_user_info.mei}`,
          kana: `${data.mst_user_info.kana_sei} ${data.mst_user_info.kana_mei}`,
          mail_address: data.login_id,
          institution: data.mst_user_info.institution,
          birth: moment(data.mst_user_info.birth).format('YYYY/MM/DD'),
          created_at: moment(data.created_at).format('YYYY/MM/DD HH:mm'),
        };
      });
    },
    nowPageCount() {
      if (this.totalRows === 0) {
        return 0;
      }
      let maxPage = Math.floor(this.totalRows / this.perPage);
      // 上記計算にあまりがあれば、ページ数をプラス1
      const mod = this.totalRows % this.perPage;
      let lastPageItemNum;
      if (mod !== 0) {
        maxPage += 1;
        lastPageItemNum = mod;
      } else {
        lastPageItemNum = this.perPage;
      }
      // 最終ページ以外は、現在の表示件数設定の値
      if (this.currentPage < maxPage) {
        return this.perPage;
      }
      // 最終ページが表示件数ぴったりの場合以外は、端数を表示
      return lastPageItemNum;
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    await this.initFetch();
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style>
  .cmsAppTable thead th:nth-of-type(1) {
    width: 70px !important;
  }
</style>

<style scoped>
  #serchWrap input, #serchWrap select {
    height: 50px;
  }

  #NumberWrap {
    height: 50px;
  }

  .search_area {
    background: #DDD;
    padding: 10px;
    margin-bottom: 30px;
  }

  .searchLong {
    width: 450px;
  }

  .searchMiddleLong {
    width: 320px;
  }

  .searchMiddle {
    width: 205px;
  }

  .searchShort {
    width: 135px;
  }

  .searchSpace {
    margin-right: 15px;
  }

  #popover {
    cursor: pointer;
    color: #FFF !important;
    text-decoration: underline;
  }

  .link {
    color: #0A8EA7 !important;
    cursor: pointer;
  }

  .link:hover {
    text-decoration: underline;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
